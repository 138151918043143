import React from 'react'
import { useDocumentTitle } from '@uidotdev/usehooks'
import PageBase from 'components/page-title/PageBase'
import HomeIcon from '@mui/icons-material/Home'
import { Box, Card, CardContent, Grid2, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import WelcomeHomeUserModal from './components/HomeWelcomeUserModal'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import { EmojiEvents } from '@mui/icons-material'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import PlayerImage from 'components/shared-components/PlayerImage'
import TeamImage from 'components/shared-components/TeamImage'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import ConnectYahooFantasyTeam from 'components/shared-components/ConnectYahooAccount'
import WeeklyFantasyTeamPerformanceDashboard from './components/WeeklyFantasyTeamPerformanceDashboard'
import FantasyTeamPerformanceReport from 'components/fantasy-team-performance-report/FantasyTeamPerformanceReport'

const TopFantasyPlayers: React.FC = React.memo(() => {
  const store = useFantaHockeyStore()

  const { isFetching: isGettingTopPlayers, data: topFantasyPlayers } = useQuery<ConnectedYahooFantasyPlayer[]>({
    queryKey: [ApiQueryKeys.GetTopFantasyPlayersQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetTopFantasyPlayersQuery]()).data
    },
    enabled: store.isConnectedToYahooFantasy
  })

  if (isGettingTopPlayers) {
    return (
      <Box>
        <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
      </Box>
    )
  }

  const getTrophyColor = (index: number): string => {
    switch (index) {
      case 0:
        return '#FFD700'
      case 1:
        return '#C0C0C0'
      case 2:
        return '#CD7F32'
    }
    return '#FFFFFF'
  }

  if (!(store.isConnectedToYahooFantasy ?? false)) {
    return <ConnectYahooFantasyTeam />
  }

  return (<>
    <Grid2 minHeight={350} maxHeight={400} container spacing={1}>
      <Box mt={1} />
      {topFantasyPlayers?.map((player, index) => (
        <>
        <Grid2 key={index} size={{ xs: 12, sm: 12, md: 12 }}>
          <List sx={{ width: '100%' }}>
            <ListItem sx={{ p: 0, my: 1 }}>
              <ListItemAvatar>
                <TeamImage src={player.teamLogoUrl} />
                <PlayerImage src={player.playerHeadshotUrl} />
              </ListItemAvatar>
              <Box mr={2} />
              <ListItemText
                primary={
                  <>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={600}
                    >
                      {player.name}
                    </Typography>
                  </>
                } />
              <Box ml={2} />
              <EmojiEvents sx={{ fontSize: 'calc(0.5dvw + 32px)', color: getTrophyColor(index) }} />
            </ListItem>
          </List>
        </Grid2>
        </>
      ))}
    </Grid2>
  </>)
})

TopFantasyPlayers.displayName = 'TopFantasyPlayers'

const Home: React.FC = React.memo(() => {
  useDocumentTitle('FantaHockey | Home')
  const fantaHockeyContext = useFantaHockeyContext()
  const store = useFantaHockeyStore()
  const [open, setOpen] = React.useState(false)
  React.useMemo(() => {
    if (fantaHockeyContext.isFirstLogin) {
      setOpen(true)
    }
  }, [fantaHockeyContext.isFirstLogin])

  return (
    <PageBase icon={HomeIcon} title={'Home'}>
      <WelcomeHomeUserModal open={open} onClose={() => { setOpen(false) }} />
      <Box p={1} m={1}>
        <Grid2 spacing={2} container>
        <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4 }}>
            <Box>
              <Typography fontFamily={'Spantaran'} variant={'h6'}>Fantasy Team Report</Typography>
              <Card sx={{ minHeight: { xs: 400, sm: 400, md: 425 }, maxHeight: { xs: 400, sm: 400, md: 425 }, overflowY: store.isConnectedToYahooFantasy ? 'scroll' : 'hidden' }}>
                <CardContent sx={{ minHeight: { xs: 400, sm: 400, md: 425 }, maxHeight: { xs: 400, sm: 400, md: 425 } }}>
                  <FantasyTeamPerformanceReport />
                </CardContent>
              </Card>
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
            <Typography fontFamily={'Spantaran'} variant={'h6'}>Top Fantasy Players</Typography>
            <Card sx={{ minHeight: { sm: 400, md: 425 }, maxHeight: { sm: 400, md: 425 } }}>
              <CardContent sx={{ minHeight: { sm: 400, md: 425 }, maxHeight: { sm: 400, md: 425 } }}>
                <TopFantasyPlayers />
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
            <Box>
            <Typography fontFamily={'Spantaran'} variant={'h6'}>Weekly Team Performance </Typography>
            <Card sx={{ minHeight: { sm: 400, md: 425 }, maxHeight: { sm: 400, md: 425 } }}>
                <CardContent sx={{ minHeight: { sm: 400, md: 425 }, maxHeight: { sm: 400, md: 425 } }}>
                  <WeeklyFantasyTeamPerformanceDashboard />
                </CardContent>
              </Card>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </PageBase>
  )
})

Home.displayName = 'Home'
export default Home
