/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { Box, Button, Card, CardContent, CircularProgress, styled, Typography } from '@mui/material'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useDocumentTitle } from '@uidotdev/usehooks'
import { useNavigate } from 'react-router'
import { initializeMsalInstance } from 'features/login/setup/microsoft/microsoftPublicClient'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { OAuthProvider, type OAuthLoginResponse } from 'features/login/models/generic/OAuthResponse'
import { type CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { Lock } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'

const AppLoginLoading: React.FC = React.memo((): JSX.Element => {
  return <>
    <Box my={4}>
      <CircularProgress color='info' />
    </Box>
  </>
})
AppLoginLoading.displayName = 'AppLoginLoading'

const AppLogin: React.FC = React.memo((): JSX.Element => {
  useDocumentTitle('FantaHockey | Login')
  const navigate = useNavigate()
  const fantaHockeyAuth = useFantaHockeyContext()
  const [msalInstance, setMsalInstance] = React.useState<IPublicClientApplication>()
  const [isLoading, setLoading] = React.useState(false)
  const { openSnackbar } = useSnackbar()
  const store = useFantaHockeyStore()

  const { mutateAsync: loginWithMicrosoft } = useMutation<OAuthLoginResponse, unknown, string>({
    mutationKey: [ApiMutationKeys.LoginWithMicrosoftMutation],
    mutationFn: async (idToken: string) => {
      return (await ApiMutationService[ApiMutationKeys.LoginWithMicrosoftMutation](idToken)).data
    }
  })

  const { mutateAsync: loginWithGoogle } = useMutation<OAuthLoginResponse, unknown, string>({
    mutationKey: [ApiMutationKeys.LoginWithGoogleMutation],
    mutationFn: async (idToken: string) => {
      return (await ApiMutationService[ApiMutationKeys.LoginWithGoogleMutation](idToken)).data
    }
  })
  React.useLayoutEffect(() => {
    if (fantaHockeyAuth.isAuthenticated) {
      navigate('/home', { replace: true })
    }
  }, [fantaHockeyAuth.isAuthenticated])
  React.useEffect(() => {
    const setupMsalLogin = async (): Promise<void> => {
      const instance = await initializeMsalInstance()
      setMsalInstance(instance)
    }

    void setupMsalLogin()
    fantaHockeyAuth.storeMsalInstance(msalInstance)
  }, [])

  const loginMicrosoftCallbackHandler = async (idToken: string): Promise<OAuthLoginResponse> => {
    return await loginWithMicrosoft(idToken)
  }

  const loginGoogleCallbackHandler = async (idToken: string | undefined): Promise<OAuthLoginResponse> => {
    return await loginWithGoogle(idToken ?? '')
  }

  const loginMicrosoftAuthentication = async (): Promise<void> => {
    try {
      if (isLoading) return
      setLoading(true)
      const response = await msalInstance?.loginPopup()
      if (response?.account !== undefined) {
        const { idToken } = response
        const oAuthLoginResponse = await loginMicrosoftCallbackHandler(idToken)
        if (oAuthLoginResponse.isAuthenticated) {
          setLoading(false)
          const loginModel = {
            securityToken: idToken ?? '',
            name: oAuthLoginResponse.name,
            isAuthenticated: true,
            email: oAuthLoginResponse.email,
            provider: OAuthProvider.Microsoft,
            isFirstLogin: oAuthLoginResponse.isFirstLogin
          }
          fantaHockeyAuth.login(loginModel)
          await store.getInitialData()
          openSnackbar('Logged In', 'success')
        }
      }
      setLoading(false)
    } catch (error: unknown) {
      console.error(error)
      setLoading(false)
    }
  }

  const googleLoginCallbackHandler = async (credentialResponse: CredentialResponse): Promise<void> => {
    try {
      if (isLoading) return
      setLoading(true)
      const oAuthLoginResponse = await loginGoogleCallbackHandler(credentialResponse.credential)
      if (oAuthLoginResponse.isAuthenticated) {
        setLoading(false)
        const loginModel = {
          securityToken: credentialResponse.credential ?? '',
          name: oAuthLoginResponse.name,
          isAuthenticated: true,
          email: oAuthLoginResponse.email,
          provider: OAuthProvider.Google,
          isFirstLogin: oAuthLoginResponse.isFirstLogin
        }
        fantaHockeyAuth.login(loginModel)
        await store.getInitialData()
        openSnackbar('Logged In', 'success')
      }
    } catch (error: unknown) {
      setLoading(false)
    }
  }

  const CenterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  }))

  const LoginText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px'
  }))

  const LogoImage = styled('img')({
    height: '24px',
    width: '24px',
    position: 'absolute',
    left: 15
  })

  if (fantaHockeyAuth.isAuthenticated) {
    return <></>
  }

  const LoginCard: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
    return <Box textAlign={'center'} m={3} sx={{ minWidth: '250px', maxWidth: '450px' }}>
      <Card elevation={24}>
        <CardContent sx={{ margin: 4, display: { xs: 'block', sm: 'block', md: 'none' } }}>
          {children}
        </CardContent>
        <CardContent sx={{ margin: 8, display: { sm: 'none', xs: 'none', md: 'block' } }}>
          {children}
        </CardContent>
      </Card>
    </Box>
  }

  const DesktopButtons = (): JSX.Element => {
    return (
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Box my={4} />
          <MicrosoftLoginButton/>
          <Box my={4} />
          <CenterBox>
          <GoogleLoginButton/>
          </CenterBox>
      </Box>
    )
  }

  const MicrosoftLoginButton = (): JSX.Element => (
    <Button sx={{ p: 1 }} variant='outlined' color='info' fullWidth onClick={async () => { await loginMicrosoftAuthentication() }}>
      <Box>
        <LogoImage src={'./assets/images/microsoft-logo.png'} alt={'Microsoft Login'} />
        <Box component="span" sx={{ ml: 2 }}>Login with Microsoft</Box>
      </Box>
    </Button>
  )

  const GoogleLoginButton = (): JSX.Element => (
    <Button sx={{ p: 1 }} variant='outlined' color='info' fullWidth>
        <LogoImage src={'./assets/images/google-login-logo.svg'} alt={'Google Login'} />
        <Box component="span">Login with Google</Box>
        <Box sx={{ opacity: 0.000000001, position: 'absolute', zIndex: 0 }}>
          <GoogleLogin
            shape='rectangular'
            size='large'
            useOneTap={true}
            onSuccess={async (credentialResponse: CredentialResponse) => {
              await googleLoginCallbackHandler(credentialResponse)
            }}
            onError={() => {
              openSnackbar('Login Failed', 'error')
            }} />
        </Box>
    </Button>
  )

  const MobileButtons = (): JSX.Element => (
    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
      <Box my={4} />
      <MicrosoftLoginButton/>
      <Box my={4} />
      <CenterBox>
      <GoogleLoginButton/>
      </CenterBox>
    </Box>
  )

  return (
    <>
      <Box p={0} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignContent={'center'}>
        <LoginCard>
          <Lock color={'info'} sx={{ fontSize: 36 }} />
          <Typography fontFamily={'Spantaran'} variant="h4" color="text.primary">
            Login
          </Typography>
          <Box my={2} />
          <LoginText color="text.primary">
            Select an authentication provider
          </LoginText>
          <DesktopButtons />
          <MobileButtons />
          {isLoading && <AppLoginLoading />}
        </LoginCard>
      </Box>
    </>
  )
})

AppLogin.propTypes = {

}

AppLogin.displayName = 'AppLogin'
export default AppLogin
