import { SwipeableDrawer, Box, Paper, Typography, Grid, styled, Button } from '@mui/material'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import PlayerImage from 'components/shared-components/PlayerImage'
import React from 'react'
import FantaHockeyScore from './FantaHockeyScore'
import PlayerFlag from './PlayerFlag'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import { grey } from '@mui/material/colors'
import { type YahooFantasyGoalieStatistics } from 'features/fantasy-team/models/yahooFantasyGoalieStatistics'
import { isMobile } from 'react-device-detect'
import { Close, HealthAndSafety, LocalHospital } from '@mui/icons-material'
import LazyImage from 'components/shared-components/LazyImage'

interface FantasyProfileHeaderProps {
  open: boolean
  onClose: () => void
  onOpen: () => void
  height?: string | number | undefined
  isFetching: boolean
  player: ConnectedYahooFantasyPlayer
  playerStatistics: YahooFantasyPlayerStatistics | YahooFantasyGoalieStatistics | undefined
  fantaHockeyScore: number | undefined
  children?: React.ReactNode
}

const FantasyProfileHeader: React.FC<FantasyProfileHeaderProps> = ({ open, onClose, onOpen, height, isFetching, player, playerStatistics, fantaHockeyScore, children }) => {
  const mapPositionCodeToPosition = (positionCode: string | undefined): string => {
    switch (positionCode) {
      case 'C':
        return 'Center'
      case 'L':
        return 'Left Wing'
      case 'R':
        return 'Right Wing'
      case 'D':
        return 'Defense'
      case 'G':
        return 'Goalie'
      default:
        return 'N/A'
    }
  }

  const Puller = styled('div')(({ theme }) => ({
    width: 35,
    height: 7,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
      backgroundColor: grey[900]
    })
  }))

  const PlayerHealthStatus = (player: ConnectedYahooFantasyPlayer): JSX.Element => {
    return <>
     {player.isInjured && <LocalHospital color='error' />}
                                    {!player.isInjured && <HealthAndSafety color='success' />}
                                    {player.isInjured && <Typography variant="body1" color="error">{player.injuryStatus}</Typography>}
                                    {!player.isInjured && <Typography variant="body1" color="success">Healthy</Typography>}
                                    <Box my={1} />
    </>
  }

  return <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        disableBackdropTransition={true}
        disableDiscovery={true}
        PaperProps={{
          sx: {
            height: height ?? 'calc(100dvh - 58px)',
            overflow: 'auto'
          }
        }}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
    >
      {isMobile && (
          <Box height={16} width={4}>
              <Puller onClick={onClose} />
          </Box>)}

        {!isMobile && (<>
            <Box display={'flex'} justifyContent={'center'} mt={1} p={1}>
                <Button variant='outlined' color='error' onClick={onClose} startIcon={<Close/>}>Close</Button>
            </Box>
        </>)}

        {isFetching
          ? <CircularContentLoader size={'36px'} thickness={4} marginBottom={'0px'} marginTop={'64px'} textAlignment={'center'} />
          : <Box
                sx={{
                  position: 'relative', // Set position to relative for the parent Box
                  px: 2,
                  pb: 2,
                  height: '100%',
                  overflow: 'auto'
                }}
            >
                {/* Blurred Background Image */}
                <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundPositionX: 'center',
                      backgroundPositionY: 'top',
                      backgroundSize: '75% 75%',
                      backgroundImage: player.fantasyScore > 0 ? `url(${player?.teamLogoUrl})` : '',
                      backgroundBlendMode: 'lighten',
                      backgroundRepeat: 'no-repeat',
                      zIndex: -1
                    }}
                />
                {/* Content */}
                <Paper sx={{ boxShadow: 'none', mt: 6 }}>
                    {player.fantasyScore === 0
                      ? <Box p={2} textAlign="center">
                            <PlayerImage
                                height="calc(1dvw + 96px)"
                                width="calc(1dvw + 96px)"
                                src={player?.playerHeadshotUrl}
                            />
                            <Box my={1} />
                            <Typography variant="h6">{player.name} does not have any statistics yet</Typography>
                        </Box>
                      : <Box>
                            <Grid
                                container
                                p={2}
                                spacing={3} // Increase spacing between grid items
                                direction="row"
                                alignItems="flex-start" // Align items at the start of each column
                                justifyContent="space-evenly" // Spread out content evenly across the container
                            >
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {PlayerHealthStatus(player)}
                                    <PlayerFlag country={playerStatistics?.birthCountry} />
                                    <Typography fontSize={'calc(0.5vw + 18px)'} fontWeight="700" textAlign="center" my={1}>
                                        #{playerStatistics?.sweaterNumber} | {player?.name}
                                    </Typography>
                                    <PlayerImage
                                        height="calc(1dvw + 96px)"
                                        width="calc(1dvw + 96px)"
                                        src={player?.playerHeadshotUrl}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container p={1} spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Grid
                                    item
                                    sm={6}
                                    xs={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    direction="column"
                                >
                                    {/* Position */}
                                    <Grid item>
                                        <Typography align="center">
                                            <span style={{ fontWeight: 700, marginRight: '8px' }}>Position:</span>
                                            {mapPositionCodeToPosition(player?.position)}
                                        </Typography>
                                    </Grid>
                                    {/* Age */}
                                    <Grid item>
                                        <Typography align='center'>
                                            <span style={{ fontWeight: 700, marginRight: '8px' }}>Age:</span>
                                            {playerStatistics?.age}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    sm={6}
                                    xs={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    direction="column"
                                    spacing={2}
                                >
                                    {/* Height */}
                                    <Grid item>
                                        <Typography align="center">
                                            <span style={{ fontWeight: 700, marginRight: '8px' }}>Height:</span>
                                            {playerStatistics?.heightInFeetAndInches}
                                        </Typography>
                                    </Grid>
                                    {/* Weight */}
                                    <Grid item>
                                        <Typography align="center">
                                            <span style={{ fontWeight: 700, marginRight: '8px' }}>Weight:</span>
                                            {playerStatistics?.weightInPounds} lbs
                                        </Typography>
                                    </Grid>
                                    </Grid>
                            </Grid>

                            <Grid
                                my={2}
                                spacing={1}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="space-around"
                            >
                                <Grid item display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <FantaHockeyScore value={fantaHockeyScore ?? 0} size={50} thickness={2} />
                                </Grid>

                                <Grid item>
                                    <Box mt={-3} />
                                    <LazyImage src='./assets/images/Yahoo!-Logo.wine.png' alt='yahoo' height={64} />
                                    <Typography variant="subtitle1" align="center" fontWeight={700} mt={-3}>
                                        {player.fantasyScore.toFixed(2)} Pts
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>}
                    {children}
                </Paper>
            </Box>}
    </SwipeableDrawer>
}

export default React.memo(FantasyProfileHeader)
