import { type TradeSuggestions } from 'features/trade-analyzer/model/tradeSuggestion'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { create } from 'zustand'

interface FantaHockeyState {
  isConnectedToYahooFantasy: boolean
  isFetchingConnectionStatus: boolean
  isFetchingTradeSuggestions: boolean
  tradeSuggestions: TradeSuggestions[]
}

interface FantaHockeyActions {
  setTradeSuggestions: (tradeSuggestions: TradeSuggestions[]) => void
  setIsConnectedToYahooFantasy: (isConnected: boolean) => void
  getInitialData: () => Promise<void>
  reset: () => void
}

const useFantaHockeyStore = create<FantaHockeyState & FantaHockeyActions>((set, get) => ({
  isConnectedToYahooFantasy: false,
  isFetchingConnectionStatus: false,
  isFetchingTradeSuggestions: false,
  tradeSuggestions: [],
  setTradeSuggestions: (tradeSuggestions: TradeSuggestions[]) => { set({ tradeSuggestions }) },
  setIsConnectedToYahooFantasy: (isConnected: boolean) => { set({ isConnectedToYahooFantasy: isConnected }) },
  reset: () => { set({ isConnectedToYahooFantasy: false, tradeSuggestions: [] }) },
  getInitialData: async () => {
    set({ isFetchingConnectionStatus: true })
    try {
      const isConnected = (await ApiQueryService[ApiQueryKeys.IsUserConnectedToYahooQuery]()).data
      set({ isConnectedToYahooFantasy: isConnected, isFetchingConnectionStatus: false })
    } catch (e) {
      console.error(e)
      set({ isFetchingTradeSuggestions: false })
    }
  }
}))

export default useFantaHockeyStore
