import { Alert, type AlertColor, Snackbar } from '@mui/material'
import React from 'react'

interface SnackbarContextType {
  snackbar: { open: boolean, message: string, severity: AlertColor, duration: number }
  openSnackbar: (message: string, severity: AlertColor) => void
  closeSnackbar: () => void
}

const SnackbarContext = React.createContext<SnackbarContextType>({
  snackbar: {
    open: false,
    duration: 3000,
    message: '',
    severity: 'info'
  },
  openSnackbar: function (message: string, severity: AlertColor): void {
    throw new Error('Function not implemented.')
  },
  closeSnackbar: function (): void {
    throw new Error('Function not implemented.')
  }
})

interface Props {
  children: React.ReactNode
}

export const SnackbarProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [snackbar, setSnackbar] = React.useState< { open: boolean, message: string, severity: AlertColor, duration: number } >({ open: false, message: '', severity: 'info', duration: 3000 })

  const openSnackbar = React.useCallback((message: string, severity: AlertColor, duration: number = 3000) => {
    setSnackbar({ open: true, message, severity, duration })
  }, [])

  const closeSnackbar = React.useCallback(() => {
    setSnackbar(prevState => ({ ...prevState, open: false }))
  }, [])

  const handleExited = React.useCallback(() => (): void => {
    setSnackbar(prevState => ({ ...prevState, message: '', severity: 'info' }))
  }, [])

  const contextValue = React.useMemo(
    () => ({ snackbar, openSnackbar, closeSnackbar }),
    [snackbar, openSnackbar, closeSnackbar]
  )

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={closeSnackbar}
        TransitionProps={{
          onExited: handleExited
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>

        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
// Create a hook to use the Snackbar context
export const useSnackbar = (): SnackbarContextType => {
  return React.useContext(SnackbarContext)
}
