import { Add } from '@mui/icons-material'
import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import LazyImage from './LazyImage'
import PropTypes from 'prop-types'

interface ConnectYahooFantasyTeamProps {
  content?: string
}

const ConnectYahooFantasyTeam: React.FC<ConnectYahooFantasyTeamProps> = React.memo(({ content }) => {
  const navigate = useNavigate()
  return (
      <Box p={1} display={'flex'} justifyContent={'center'} alignContent={'center'} textAlign={'center'}>
        <Box>
        <LazyImage height={'calc(0.5vw + 100px)'} src="./assets/images/Yahoo!-Logo.wine.png" alt={'Yahoo!'} />
        <Typography variant="body1">
            {content ?? 'You must connect your Yahoo! fantasy account to view your team'}
          </Typography>
          <Box mt={2}/>
          <Button
            variant="outlined" color="success" size='medium'
            onClick={() => { navigate('/connect-my-fantasy-team') }}>
            <Add/> Connect
          </Button>
        </Box>
      </Box>
  )
})

ConnectYahooFantasyTeam.propTypes = {
  content: PropTypes.string.isRequired
}

ConnectYahooFantasyTeam.displayName = 'ConnectYahooFantasyTeam'
export default ConnectYahooFantasyTeam
