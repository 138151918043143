import { Box } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import React from 'react'
import ConnectYahooFantasyTeamStepper from './ConnectYahooFantasyTeamStepper'
import { Add } from '@mui/icons-material'
import PageBase from 'components/page-title/PageBase'
import { useQueryClient } from '@tanstack/react-query'
import { ApiQueryKeys } from 'services/query/apiQueryService'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { useNavigate } from 'react-router'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'

const ConnectMyFantasyTeam: React.FC = () => {
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const store = useFantaHockeyStore()

  useDocumentTitle('FantaHockey | Connect My Fantasy Team')

  return (
    <PageBase icon={Add} title={'Connect Team'}>
      <Box mt={2} />
        <ConnectYahooFantasyTeamStepper
            onCompletedCallback={async (teamName: string) => {
              await queryClient.invalidateQueries({
                queryKey: [
                  ApiQueryKeys.IsUserConnectedToYahooQuery,
                  ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery,
                  ApiQueryKeys.GetTopFantasyPlayersQuery
                ]
              })
              snackbar.openSnackbar(`Connected to ${teamName}`, 'success')
              store.setIsConnectedToYahooFantasy(true)
              navigate('/my-fantasy-team', { replace: true })
            }}
        />
    </PageBase>
  )
}

ConnectMyFantasyTeam.displayName = 'ConnectMyFantasyTeam'
export default ConnectMyFantasyTeam
