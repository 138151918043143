import { useNetworkState } from '@uidotdev/usehooks'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import React from 'react'

const useNetworkNotification = (): void => {
  const { online, effectiveType } = useNetworkState()
  const snackbar = useSnackbar()

  React.useMemo(() => {
    if (!online) {
      snackbar.openSnackbar(
        'You are offline! Please check your internet connection',
        'error'
      )
    }
  }, [online])

  React.useMemo(() => {
    if (effectiveType === 'slow-2g' || effectiveType === '2g') {
      snackbar.openSnackbar('You are on a slow internet connection', 'warning')
    }
  }, [effectiveType])
}

export default useNetworkNotification
