export interface OAuthResponse {
  accessToken: string
  idToken: string
  name: string
  isAuthenticated: boolean
}

export interface OAuthLoginResponse {
  securityToken: string
  name: string
  email: string
  provider: OAuthProvider
  isAuthenticated: boolean
  isFirstLogin: boolean
}

export enum OAuthProvider {
  Microsoft = 'Microsoft',
  Google = 'Google',
}

export const LoginLocalStorageKey = 'fantaHockey:Token'
export const IsAuthenticatedLocalStorageKey = 'fantaHockey:isAuthenticated'
export const NameLocalStorageKey = 'fantaHockey:name'
export const EmailLocalStorageKey = 'fantaHockey:email'
export const ProviderLocalStorageKey = 'fantaHockey:provider'
