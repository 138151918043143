import React from 'react'
import { type Effect, LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

export interface PlayerImageProps {
  src: string | undefined
  alt?: string
  height?: string | number
  width?: string | number
  transitionDelay?: string
  effect?: Effect
}

export const PlayerImage: React.FC<PlayerImageProps> = ({ alt, src, height, width, transitionDelay, effect }) => {
  return <LazyLoadImage
    alt={alt}
    loading='eager'
    effect={'opacity'}
    wrapperProps={{
      style: { transitionDelay: transitionDelay ?? '0.125s' }
    }}
    style={
      {
        backgroundColor: '#bdbdbd',
        borderRadius: '50%',
        height: height ?? 'calc(0.75dvw + 64px)',
        width: width ?? 'calc(0.75dvw + 64px)'
      }
    }
    placeholderSrc={src}
    src={src}
     />
}

export default React.memo(PlayerImage)
