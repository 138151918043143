import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItemAvatar,
  Typography
} from '@mui/material'
import { AccessTime, AutoAwesome, Bolt } from '@mui/icons-material'
import PlayerImage from 'components/shared-components/PlayerImage'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import {
  type PlayerToTrade,
  type TradeSuggestions
} from 'features/trade-analyzer/model/tradeSuggestion'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import ConnectYahooAccount from 'components/shared-components/ConnectYahooAccount'

const TradeEdgeSuggestionPlayer: React.FC<{ player: PlayerToTrade }> = React.memo(
  ({ player }) => {
    return (
      <Box
        key={player.playerId}
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: `url(${player.teamLogoUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backdropFilter: 'blur(6px)',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0,0,0,0.7))',
            zIndex: 0
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            px: 3,
            py: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#fff'
          }}
        >
          <ListItemAvatar>
            <PlayerImage
              alt={player.fullName}
              src={player.headshotUrl}
            />
          </ListItemAvatar>
          <Typography variant="h6" fontWeight={800} sx={{ mt: 1, textAlign: 'center' }}>
            {player.fullName}
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', gap: 2 }}>
            <Typography variant="body1" color="inherit" fontWeight={700}>
              Season Statistics
            </Typography>
          </Box>
          <Box sx={{ mt: 0, display: 'flex', gap: 2 }}>
            <Typography variant="body1" color="inherit">
              G: {player.goals}
            </Typography>
            <Typography variant="body1" color="inherit">
              A: {player.assists}
            </Typography>
            <Typography variant="body1" color="inherit">
              P: {player.points}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
)
TradeEdgeSuggestionPlayer.propTypes = {
  player: PropTypes.shape({
    playerId: PropTypes.number.isRequired,
    teamLogoUrl: PropTypes.string.isRequired,
    headshotUrl: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    goals: PropTypes.number.isRequired,
    assists: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
    teamName: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
    pointsLast5Games: PropTypes.number.isRequired,
    careerGoals: PropTypes.number.isRequired,
    careerAssists: PropTypes.number.isRequired,
    careerPoints: PropTypes.number.isRequired
  }).isRequired
}
TradeEdgeSuggestionPlayer.displayName = 'TradeEdgeSuggestionPlayer'

const TradeEdgeSuggestions: React.FC = React.memo((): JSX.Element => {
  const store = useFantaHockeyStore()
  const navigate = useNavigate()

  const {
    isFetching,
    data: tradeSuggestions,
    refetch: getTradeSuggestions
  } = useQuery<TradeSuggestions[]>({
    queryKey: [ApiQueryKeys.GetTradeEdgeSuggestionsQuery],
    queryFn: async () => {
      const suggestions = (
        await ApiQueryService[ApiQueryKeys.GetTradeEdgeSuggestionsQuery]()
      ).data
      store.setTradeSuggestions(suggestions)
      return suggestions
    },
    enabled: false,
    initialData: store.tradeSuggestions
  })

  const handleConnectClick = useCallback(() => {
    navigate('/connect-my-fantasy-team')
  }, [navigate])

  const handleGenerateClick = useCallback(() => {
    void getTradeSuggestions()
  }, [getTradeSuggestions])

  const content = useMemo(() => {
    if (!(store.isConnectedToYahooFantasy ?? false)) {
      return (
        <ConnectYahooAccount content='You must connect your Yahoo! account to view and generate trade suggestions.'/>
      )
    }

    if (isFetching) {
      return (
        <Box
          my={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          sx={{
            gap: 2
          }}
        >
          <Typography variant="body1" sx={{ mt: 1, color: 'text.secondary', display: 'flex' }}>
          <AutoAwesome sx={{ color: 'white' }} />  <Box mr={1}/> Creating trade suggestions
          </Typography>
          <Typography variant="body1" sx={{ mt: 0, color: 'text.secondary', display: 'flex' }}>
          <AccessTime sx={{ color: 'white' }} /> <Box mr={1} /> This may take a minute
          </Typography>
        </Box>
      )
    }

    if (tradeSuggestions?.length === 0) {
      return (
        <Box
          p={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="body1" gutterBottom my={2}>
            Want some help with your fantasy hockey trades? Let Trade Edge generate some trade
            suggestions for you.
          </Typography>
          <Box my={2}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ minWidth: 300, maxWidth: 500 }}
              onClick={handleGenerateClick} // Use the stable handler
            >
              <AutoAwesome /> <Box ml={1} />
              Generate Trade Suggestions
            </Button>
          </Box>
        </Box>
      )
    }

    return (
    <Box p={1} m={1}>
        <Grid container spacing={3}>
          {tradeSuggestions?.map((tradeSuggestion) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={tradeSuggestion.reasonForTradeEdge}>
              <Card sx={{ height: '100%', boxShadow: 5 }}>
                <CardContent sx={{ p: 0 }}>
                  {/* PLAYERS TO TRADE SECTION */}
                  {tradeSuggestion.playersToTrade.map((player) => (
                    <TradeEdgeSuggestionPlayer key={player.playerId} player={player} />
                  ))}
                  {/* PLAYERS TO RECEIVE SECTION */}
                  {tradeSuggestion.playersToReceive.map((player) => (
                    <TradeEdgeSuggestionPlayer key={player.playerId} player={player} />
                  ))}
                  {/* REASON FOR TRADE EDGE */}
                  <Box sx={{ p: 3 }}>
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      gutterBottom
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Bolt /> Trade Edge
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      {tradeSuggestion.reasonForTradeEdge}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box mt={4} />
        <Box my={2} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ minWidth: 300, maxWidth: 500 }}
            onClick={handleGenerateClick} // Use the stable handler
          >
            <AutoAwesome /> <Box ml={1} />
            Generate New Trade Suggestions
          </Button>
        </Box>
      </Box>
    )
  }, [
    store.isConnectedToYahooFantasy,
    isFetching,
    store.isFetchingTradeSuggestions,
    tradeSuggestions,
    handleConnectClick,
    handleGenerateClick
  ])

  return <Box>{content}</Box>
})
TradeEdgeSuggestions.displayName = 'TradeEdgeSuggestions'

export default TradeEdgeSuggestions
