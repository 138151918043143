import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

interface Props {
  size: string
  thickness: number
  marginTop: string
  marginBottom: string
  textAlignment: 'center' | 'left' | 'right'
}

const CircularContentLoader: React.FC<Props> = React.memo((props): JSX.Element => {
  return <>
        <Box textAlign={props.textAlignment} mb={props.marginBottom} mt={props.marginTop}>
          <CircularProgress color='info' size={props.size} thickness={props.thickness} />
        </Box>
    </>
})

CircularContentLoader.propTypes = {
  size: PropTypes.string.isRequired,
  thickness: PropTypes.number.isRequired,
  marginTop: PropTypes.string.isRequired,
  textAlignment: PropTypes.any.isRequired,
  marginBottom: PropTypes.string.isRequired
}

CircularContentLoader.displayName = 'CircularContentLoader'
export default CircularContentLoader
