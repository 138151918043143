import React from 'react'
import AppLoader from './components/app-loader/AppLoader'
import AppHeader from 'components/app-header/AppHeader'
import { Box } from '@mui/material'
import { Outlet } from 'react-router'
import { setupInterceptors } from 'services/http/axiosInstance'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import useNetworkNotification from 'hooks/network/UseNetworkNotification'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'

const App = (): JSX.Element => {
  const fantaHockeyAuth = useFantaHockeyContext()
  const store = useFantaHockeyStore()
  setupInterceptors()
  useNetworkNotification()

  React.useEffect(() => {
    const clearAppCache = async (): Promise<void> => {
      await caches?.delete(fantaHockeyAuth.appName)
    }
    if (fantaHockeyAuth.isAuthenticated) {
      void store.getInitialData().then(() => {
        fantaHockeyAuth.setSplashScreen(false)
      })
    } else {
      setTimeout(() => {
        fantaHockeyAuth.setSplashScreen(false)
      }, 500)
    }
    void clearAppCache()
  }, [])

  if (fantaHockeyAuth.isSplashScreenVisible) {
    return <AppLoader />
  }

  return (
    <>
      <Box pb={4}>
        <AppHeader />
      </Box>
        <Outlet />
    </>
  )
}

export default App
