import React from 'react'
import {
  Box,
  Typography,
  Button,
  Link,
  List,
  ListItem,
  Paper,
  Container,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material'
import { Web, SportsHockey } from '@mui/icons-material'

const steps = [
  {
    label: 'Log in to Yahoo Fantasy',
    content: (
      <>
        <Typography variant="body1" paragraph>
          Go to{' '}
          <Link
            href="https://sports.yahoo.com/fantasy"
            target="_blank"
            sx={{ color: 'white' }}
            rel="noopener"
          >
            Yahoo Fantasy Sports
          </Link>{' '}
          and log in with your Yahoo account.
        </Typography>
      </>
    )
  },
  {
    label: 'Open Your Fantasy Hockey League',
    content: (
      <Typography variant="body1" paragraph>
        Click on the &quot;Fantasy&quot; tab at the top, then select &quot;Hockey.&quot;
        Choose your specific league from the list.
      </Typography>
    )
  },
  {
    label: 'Check the URL for the League ID',
    content: (
      <>
        <Typography variant="body1" paragraph>
          After you’re on your league’s homepage, look at the web address (URL) in your browser.
          Your League ID is the series of numbers that comes after <code>/hockey/</code> in the URL.
        </Typography>
        <Typography variant="caption" paragraph sx={{ display: 'block', mt: 1 }}>
          For example:
          <br />
          <code>https://.../hockey/12345</code>
          <br />
          In this example, the League ID is <strong>12345</strong>.
        </Typography>
      </>
    )
  },
  {
    label: 'League Settings Page (Alternative Method)',
    content: (
      <>
        <Typography variant="body1" paragraph>
          Alternatively, you can find the League ID in your league’s settings:
        </Typography>
        <List dense sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            From your league homepage, click on &quot;Commissioner&quot;
            (if you’re the commissioner) or &quot;League Settings.&quot;
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            The League ID will be listed in the &quot;League Information&quot; section.
          </ListItem>
        </List>
      </>
    )
  }
]

const YahooFantasyStepsToConnect: React.FC = (): JSX.Element => {
  return (
    <Paper
      elevation={3}
      sx={{
        color: '#fff',
        p: { xs: 2, sm: 3, md: 4 },
        m: 1
      }}
    >
      <Container sx={{ mt: 4 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <SportsHockey fontSize="large" sx={{ mr: 1 }} />
          <Typography variant="h5" component="h1">
            How to Find Your Yahoo Fantasy Hockey League ID
          </Typography>
        </Box>

        <Stepper orientation="vertical" activeStep={-1} sx={{ pl: 2, pr: 2 }}>
          {steps.map((step, index) => (
            <Step key={index} expanded>
              <StepLabel>
                <Typography variant="h6" sx={{ color: '#fff' }}>
                  {`${index + 1}. ${step.label}`}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.content}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button
            startIcon={<Web />}
            variant="outlined"
            color="inherit"
            href="https://sports.yahoo.com/fantasy"
            target="_blank"
            rel="noopener"
          >
            Go to Yahoo! Fantasy
          </Button>
        </Box>
      </Container>
    </Paper>
  )
}

export default React.memo(YahooFantasyStepsToConnect)
