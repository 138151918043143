import React from 'react'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import { ExitToApp, Folder, ManageAccounts, MultilineChart, RemoveCircle, SportsHockey, Work } from '@mui/icons-material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import PageBase from 'components/page-title/PageBase'
import LazyImage from 'components/shared-components/LazyImage'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import DynamicIcon from 'components/helpers/DynamicIcon'
import { type YahooConnectedTeamProfile } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
// You might import a shared theme, referencing your existing theme import, e.g.
// import theme from 'theme/AppTheme'

/**
 * Styled "ProfileCard" changed to Paper
 * (keeps the same name but the underlying component is Paper).
 */
const ProfileCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2)
}))

/**
 * We keep CardContentWrapper for spacing, but you could rename to Box
 * if you prefer. This just ensures we don’t disrupt your existing
 * layout logic.
 */
const CardContentWrapper = styled('div')(({ theme }) => ({
  gap: theme.spacing(2)
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 700,
  textAlign: 'center',
  width: '100%'
}))

const TitleSubTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 600,
  fontSize: '1.2rem',
  textAlign: 'center',
  width: '100%'
}))

const RankingBadge = ({ rank }: { rank: string | undefined }): JSX.Element => {
  const theme = useTheme()

  const getEndingForRank = (rank: string | undefined): string => {
    if (rank == null) {
      return ''
    }

    if (rank === '1') {
      return 'st'
    } else if (rank === '2') {
      return 'nd'
    } else if (rank === '3') {
      return 'rd'
    } else {
      return 'th'
    }
  }

  const getRankingColor = (rank: string | undefined): string => {
    if (rank == null) {
      return theme.palette.text.primary
    }

    if (Number.parseInt(rank) <= 3) {
      return theme.palette.success.main
    } else if (Number.parseInt(rank) > 3 && Number.parseInt(rank) <= 8) {
      return theme.palette.primary.light
    } else {
      return theme.palette.error.main
    }
  }

  const badgeGradient = `linear-gradient(90deg, ${getRankingColor(rank)} 20%, ${getRankingColor(rank)} 100%)`

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        background: badgeGradient,
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: '9999px',
        py: 1,
        px: 2,
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)'
      }}
    >
      <Typography variant="body1" component="span" sx={{ fontWeight: 'inherit' }}>
        {rank}
        {getEndingForRank(rank)} place
      </Typography>
    </Box>
  )
}

const ManageMyFantasyTeam: React.FC = () => {
  const store = useFantaHockeyStore()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const { isFetching, data: fantasyTeamProfile } = useQuery<YahooConnectedTeamProfile>({
    queryKey: [ApiQueryKeys.GetConnectedFantasyTeamProfileQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetConnectedFantasyTeamProfileQuery]()).data
    },
    enabled: true
  })

  const { mutateAsync: deleteYahooFantasyTeam } = useMutation<boolean>({
    mutationKey: [ApiMutationKeys.DeleteYahooFantasyTeamMutation],
    mutationFn: async () => {
      return (await ApiMutationService[ApiMutationKeys.DeleteYahooFantasyTeamMutation]()).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery]
      })
    }
  })

  const disconnectYahooFantasyTeam = async (): Promise<void> => {
    await deleteYahooFantasyTeam()
    store.setIsConnectedToYahooFantasy(false)
    store.setTradeSuggestions([])
    snackbar.openSnackbar(`Disconnected from ${fantasyTeamProfile?.team.name}`, 'success')
    navigate('/connect-my-fantasy-team', { replace: true })
  }

  const MAX =
    (Number.parseInt(
      fantasyTeamProfile?.league.standings?.teamList.teams[0].teamStandings?.outcomeTotals.wins ??
      '0'
    ) ?? 0) +
    (Number.parseInt(
      fantasyTeamProfile?.league.standings?.teamList.teams[0].teamStandings?.outcomeTotals.losses ??
      '0'
    ) ?? 0)
  const MIN = 0
  const normalise = (value: number): number => ((value - MIN) * 100) / (MAX - MIN)

  return (
    <PageBase title={'Manage Team'} icon={Work}>
      <ProfileCard>
        <CardContentWrapper>
          {isFetching && (
            <Box my={3} display="flex" justifyContent="center">
              <CircularProgress size="36px" thickness={4} />
            </Box>
          )}

          {!isFetching && (store.isConnectedToYahooFantasy ?? false) && (
            <Grid container spacing={2}>
              <Grid item justifyContent={'center'} justifyItems={'center'} alignContent={'center'} alignItems={'center'} xs={12}>
                <Typography display={'flex'} alignItems={'center'} alignContent={'center'} variant="h6" mb={1} fontWeight={600}>
                  <SportsHockey /> <Box ml={1} /> Fantasy League
                </Typography>
                <Divider sx={{ my: 1 }} />
                <TitleTypography my={2}> {fantasyTeamProfile?.league.name}</TitleTypography>
              </Grid>

              <Grid item xs={12}>
                <Typography display={'flex'} justifyContent={'center'} justifyItems={'center'} alignContent={'center'} alignItems={'center'} variant="h6" mb={1} fontWeight={600}>
                  <Folder /> <Box ml={1} /> Team Information
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <RankingBadge
                  rank={
                    fantasyTeamProfile?.league.standings?.teamList.teams.find(
                      (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                    )?.teamStandings?.rank
                  }
                />
              </Grid>

              <Grid item xs={6} md={4} display="flex" gap={2} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                <LazyImage
                  borderRadius="50%"
                  height={'calc(0.25vw + 75px)'}
                  src={fantasyTeamProfile?.team.teamLogos.teamLogo.url}
                  alt={fantasyTeamProfile?.team.name}
                />
              </Grid>
              <Grid item xs={6} md={4} display="flex" gap={2} alignContent={'center'}>
                <TitleSubTypography>{fantasyTeamProfile?.team.name}</TitleSubTypography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" fontWeight={600} textAlign="center">
                  {(fantasyTeamProfile?.team.managerList?.managers?.length ?? 0) > 1
                    ? 'Team Managers'
                    : 'Team Manager'}
                </Typography>
                <Stack
                  mt={2}
                  direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {fantasyTeamProfile?.team.managerList?.managers.map((manager) => (
                    <Chip
                      key={manager.guid}
                      label={manager.nickname}
                      sx={{
                        fontWeight: 600,
                        color: '#ffffff',
                        backgroundColor: (theme) => theme.palette.primary.light
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography mt={2} display={'flex'}justifyContent={'center'} justifyItems={'center'} alignContent={'center'} alignItems={'center'} variant="h6" mb={1} fontWeight={600}>
                  <MultilineChart /> <Box ml={1} /> Team Statistics
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper variant="outlined" sx={{ p: 2, alignContent: 'center', textAlign: 'center', minHeight: 100 }}>
                  <Box display="flex" gap={{ xs: 2, md: 4 }} flexDirection={{ xs: 'column', md: 'row' }} alignContent={'center'}>
                    <Typography>
                      <strong>Season Points:</strong>{' '}
                      {
                        fantasyTeamProfile?.league.standings.teamList.teams.find(
                          (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                        )?.teamStandings?.pointsFor
                      }
                    </Typography>
                    <Typography>
                      <strong>Win Percentage:</strong>{' '}
                      {
                        fantasyTeamProfile?.league.standings.teamList.teams.find(
                          (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                        )?.teamStandings?.outcomeTotals.percentage
                      }
                    </Typography>
                    <Typography>
                      <strong>Waiver Priority:</strong>{' '}
                      {
                        fantasyTeamProfile?.league.standings.teamList.teams.find(
                          (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                        )?.waiverPriority
                      }
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 100,
                    gap: 1
                  }}
                >
                  <Typography fontWeight={700}>Team Record</Typography>
                  <Box minWidth="100%">
                    <Typography variant="subtitle2">
                      {
                        fantasyTeamProfile?.league.standings?.teamList.teams.find(
                          (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                        )?.teamStandings?.outcomeTotals?.wins
                      }{' '}
                      Wins
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="success"
                      value={normalise(
                        Number.parseInt(
                          fantasyTeamProfile?.league.standings?.teamList.teams.find(
                            (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                          )?.teamStandings?.outcomeTotals?.wins ?? '0'
                        )
                      )}
                    />
                  </Box>
                  <Box minWidth="100%">
                    <Typography variant="subtitle2">
                      {
                        fantasyTeamProfile?.league.standings?.teamList.teams.find(
                          (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                        )?.teamStandings?.outcomeTotals?.losses
                      }{' '}
                      Losses
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="error"
                      value={normalise(
                        Number.parseInt(
                          fantasyTeamProfile?.league.standings?.teamList.teams.find(
                            (team) => team.teamKey === fantasyTeamProfile?.team.teamKey
                          )?.teamStandings?.outcomeTotals?.losses ?? '0'
                        )
                      )}
                    />
                  </Box>
                </Paper>
              </Grid>

              {/* Trades & Moves */}
              <Grid item xs={12} md={4}>
                <Paper variant="outlined" sx={{ p: 2, alignContent: 'center', textAlign: 'center', minHeight: 100 }}>
                  <Box display="flex" gap={4} justifyContent="center" flexDirection="row" mb={1}>
                    <Typography variant="body1">
                      <strong>Trades</strong>
                    </Typography>
                    <Typography variant="body1">
                      <strong>Moves</strong>
                    </Typography>
                  </Box>
                  <Box display="flex" gap={8} justifyContent="center" flexDirection="row">
                    <Typography variant="body1">
                      {fantasyTeamProfile?.team.numberOfTrades}
                    </Typography>
                    <Typography variant="body1">
                      {fantasyTeamProfile?.team.numberOfMoves}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} my={2}>
              <Typography display={'flex'}justifyContent={'center'} justifyItems={'center'} alignContent={'center'} alignItems={'center'} variant="h6" mb={1} fontWeight={600}>
                  <ManageAccounts /> <Box ml={1} /> Team Connection
                </Typography>
                <Divider sx={{ my: 1, mb: 2 }} />
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<DynamicIcon Icon={ExitToApp} color="success" fontSizeMd={24} />}
                    onClick={() => {
                      navigate('/my-fantasy-team', { replace: true })
                    }}
                  >
                    Go to Yahoo! Fantasy Team
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DynamicIcon Icon={RemoveCircle} color="error" fontSizeMd={24} />}
                    onClick={() => {
                      void disconnectYahooFantasyTeam()
                    }}
                  >
                    Remove Yahoo! Fantasy Team
                  </Button>
                </Stack>
              </Grid>

            </Grid>
          )}
        </CardContentWrapper>
      </ProfileCard>
    </PageBase>
  )
}

ManageMyFantasyTeam.displayName = 'ManageMyFantasyTeam'

export default React.memo(ManageMyFantasyTeam)
