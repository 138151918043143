import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks'
import { EmailLocalStorageKey, IsAuthenticatedLocalStorageKey, LoginLocalStorageKey, NameLocalStorageKey, ProviderLocalStorageKey, type OAuthLoginResponse } from 'features/login/models/generic/OAuthResponse'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { googleLogout } from '@react-oauth/google'
interface AuthProviderChildren {
  children: string | JSX.Element | JSX.Element[]
}

interface GlobalAuthContext {
  appName: string
  token?: string | null
  name?: string
  isAuthenticated: boolean
  isFirstLogin: boolean
  login: (response: OAuthLoginResponse) => boolean
  logout: () => void
  msalInstance?: IPublicClientApplication
  storeMsalInstance: (instance: IPublicClientApplication | undefined) => void
  email: string
  provider: string
  setSplashScreen: (value: boolean) => void
  getProfileNameFirstLetter: () => string | null
  isSplashScreenVisible: boolean
}

const GlobalContext = React.createContext<GlobalAuthContext>({} as unknown as GlobalAuthContext)

export const FantaHockeyProvider = ({ children }: AuthProviderChildren): JSX.Element => {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useSessionStorage<boolean>(IsAuthenticatedLocalStorageKey, false)
  const [name, setName] = useLocalStorage<string>(NameLocalStorageKey)
  const [token, setToken] = useLocalStorage<string | null>(LoginLocalStorageKey)
  const [msalInstance, setMsalInstance] = React.useState<IPublicClientApplication>()
  const [email, setEmail] = useLocalStorage<string>(EmailLocalStorageKey)
  const [provider, setProvider] = useLocalStorage<string>(ProviderLocalStorageKey)
  const [isSplashScreenVisible, setIsSplashScreenVisible] = React.useState<boolean>(true)
  const [isFirstLogin, setIsFirstLogin] = React.useState<boolean>(false)
  const appName = 'fantahockey-app'
  const storeMsalInstance = (instance: IPublicClientApplication | undefined): void => {
    setMsalInstance(instance)
  }

  const login = (response: OAuthLoginResponse): boolean => {
    setToken(response.securityToken)
    setName(response.name)
    setEmail(response.email)
    setProvider(response.provider)
    setIsFirstLogin(response.isFirstLogin)
    setIsAuthenticated(true)
    navigate('/home', { replace: true })
    return true
  }

  const logout = (): void => {
    setIsAuthenticated(false)
    setName('')
    setToken('')
    try {
      switch (provider) {
        case 'Microsoft':
          void msalInstance?.logout()
          break
        case 'Google':
          googleLogout()
          break
        default:
          break
      }
    } catch (error) {
      navigate('/login', { replace: true })
    }
  }

  const setSplashScreen = (value: boolean): void => {
    setIsSplashScreenVisible(value)
  }

  const getProfileNameFirstLetter = (): string => {
    return name?.charAt(0).toUpperCase() ?? ''
  }

  const context = React.useMemo(
    () => ({
      isAuthenticated,
      name,
      isFirstLogin,
      login,
      logout,
      appName,
      email,
      msalInstance,
      storeMsalInstance,
      provider,
      setSplashScreen,
      isSplashScreenVisible,
      getProfileNameFirstLetter,
      token
    }),
    [isAuthenticated, isSplashScreenVisible, isFirstLogin, email, name, msalInstance, provider, token, appName, getProfileNameFirstLetter, login, logout, setSplashScreen, storeMsalInstance]
  )
  return <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
}

FantaHockeyProvider.propTypes = {
  children: PropTypes.any.isRequired
}

FantaHockeyProvider.displayName = 'AuthContext'

export const useFantaHockeyContext = (): GlobalAuthContext => {
  return React.useContext<GlobalAuthContext>(GlobalContext)
}
