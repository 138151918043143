import { Divider, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { type YahooFantasyGoalieStatistics } from 'features/fantasy-team/models/yahooFantasyGoalieStatistics'
import PlayerStatisticGridItem from 'components/shared-components/PlayerStatisticSection'
import FantasyProfileHeader from './FantasyProfileHeader'

interface GoalieStatsSwipeableDrawerProps {
  open: boolean
  height?: number | string
  onOpen: () => void
  goalie: ConnectedYahooFantasyPlayer
  onClose: () => void
}

const GoalieStatsSwipeableDrawer: React.FC<GoalieStatsSwipeableDrawerProps> = ({ open, onClose, onOpen, height, goalie }) => {
  const { isFetching, data: goalieStatistics, refetch: getGoalieStatistics } = useQuery<YahooFantasyGoalieStatistics>({
    queryKey: [ApiQueryKeys.GetYahooFantasyGoalieStatistics],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyGoalieStatistics](goalie?.playerId)).data
    },
    enabled: !(goalie?.playerId === 0)
  })

  const { data: fantaHockeyScore, refetch: getFantaHockeyGoalieScore } = useQuery<number>({
    queryKey: [ApiQueryKeys.GetGoalieFantaHockeyScore],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetGoalieFantaHockeyScore](goalie.playerId)).data
    },
    enabled: !(goalie.playerId === 0)
  })

  React.useEffect(() => {
    const fetchPlayerStatistics = async (): Promise<void> => {
      if (goalie.playerId !== 0) {
        await getGoalieStatistics()
        await getFantaHockeyGoalieScore()
      }
    }
    void fetchPlayerStatistics()
  }, [goalie])

  return <FantasyProfileHeader isFetching={isFetching} open={open} onClose={onClose} onOpen={onOpen} fantaHockeyScore={fantaHockeyScore} height={height} player={goalie} playerStatistics={goalieStatistics}>
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.wins} statisticTitle="Wins" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.losses} statisticTitle="Losses" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.overtimeLosses} statisticTitle="OT Losses" maxWidth='75px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.savePercentage?.toFixed(3)} statisticTitle="SV%" statisticTooltip="Save Percentage" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.goalsAgainstAverage?.toFixed(2)} statisticTitle="GAA" statisticTooltip="Goals Against Average" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.goalsAgainst} statisticTitle="GA" statisticTooltip="Goals Against" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={goalieStatistics?.saves} statisticTitle="Saves" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.shotsAgainst} statisticTitle="SA" statisticTooltip="Shots Against" maxWidth='75px' />
              <PlayerStatisticGridItem statistic={goalieStatistics?.shutouts} statisticTitle="Shutouts" maxWidth='50px' />
            </Grid>
            <Divider />
      </FantasyProfileHeader>
}

GoalieStatsSwipeableDrawer.displayName = 'GoalieStatsSwipeableDrawer'
GoalieStatsSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goalie: PropTypes.any.isRequired
}

export default React.memo(GoalieStatsSwipeableDrawer)
