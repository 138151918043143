import React, { useCallback } from 'react'
import { Delete, Person } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import PageBase from 'components/page-title/PageBase'

const MyProfile: React.FC = (): JSX.Element => {
  // Set the document title
  useDocumentTitle('FantaHockey | My Profile')
  const { openSnackbar } = useSnackbar()
  // Destructure the auth context
  const { name, email, getProfileNameFirstLetter, logout } = useFantaHockeyContext()

  // Memoized logout handler
  const deleteAccount = useCallback(() => {
    logout()
    openSnackbar('Account deleted', 'success')
  }, [logout])

  return (
    <PageBase icon={Person} title="My Profile">
      <Grid container spacing={2}>
        <Grid item xs={12}>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ margin: 2, padding: 4 }} elevation={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {/* Avatar */}
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt={name}
              >
                {getProfileNameFirstLetter()}
              </Avatar>

              {/* Name & Email */}
              <Box sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="h6" color="text.primary">
                  {name}
                </Typography>
                <Typography variant="subtitle2" color="text.primary">
                  {email}
                </Typography>
              </Box>

              {/* Delete Account Button */}
              <Button
                variant="outlined"
                color="error"
                startIcon={<Delete />}
                onClick={deleteAccount}
              >
                Delete Account
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </PageBase>
  )
}

MyProfile.displayName = 'MyProfile'
export default MyProfile
