import React from 'react'
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  useTheme,
  Divider,
  Stack
} from '@mui/material'
import { AutoAwesome, BarChart, Casino, TrendingUpSharp } from '@mui/icons-material'
import ConfettiExplosion from 'react-confetti-explosion'

interface FantaHockeyWelcomeModalProps {
  open: boolean
  onClose: () => void
}

const WelcomeHomeUserModal: React.FC<FantaHockeyWelcomeModalProps> = ({
  open,
  onClose
}) => {
  const theme = useTheme()
  const [confetti, setConfetti] = React.useState<boolean>(false)
  return (
    <>
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.palette.background.paper,
            boxShadow: 24,
            minWidth: 300,
            maxWidth: 600,
            borderRadius: 2,
            p: 3,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography fontFamily={'Spantaran'} variant="h5" component="h2">
                Welcome to FantaHockey!
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Stack direction="column" spacing={2} sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <TrendingUpSharp color="info" />
              <Typography variant="body1">
              <strong>Ultimate Fantasy Hockey Companion:</strong> FantaHockey is designed to help you create, analyze, and manage winning teams with ease.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <AutoAwesome color="info" />
              <Typography variant="body1">
                <strong>AI-Powered Insights:</strong> Get intelligent player trading recommendations tailored to your team’s unique strategy and league dynamics.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <BarChart color="info" />
              <Typography variant="body1">
              <strong>Real-Time Data:</strong> Seamlessly integrate up-to-the-minute stats and info from Yahoo! and the NHL for a competitive edge.</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Casino color="info" />
              <Typography variant="body1">
              <strong>Betting Insights:</strong> Get the latest betting odds and insights to help you make the best decisions for your sportsbook.</Typography>
            </Box>
          </Stack>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              variant="outlined"
              color="success"
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setConfetti(true)
              }}
            >
              Get Started
              {confetti && <ConfettiExplosion height={3000} duration={2750} force={0.3} zIndex={9999} onComplete={onClose}/>}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
    </>
  )
}

export default WelcomeHomeUserModal
