import React from 'react'
import { type Effect, LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

export interface LazyImageProps {
  src: string | undefined
  loading?: 'lazy' | 'eager' | undefined
  alt?: string
  height?: string | number
  width?: string | number
  transitionDelay?: string
  borderRadius?: string
  effect?: Effect
}

export const LazyImage: React.FC<LazyImageProps> = ({ alt, src, height, width, transitionDelay, loading, effect, borderRadius }) => {
  return <LazyLoadImage
    alt={alt}
    loading={loading}
    effect={effect ?? 'opacity'}
    wrapperProps={{
      style: { transitionDelay: transitionDelay ?? '0.125s' }
    }}
    style={
      {
        height,
        width,
        borderRadius
      }
    }
    placeholderSrc={src}
    src={src}
     />
}

export default React.memo(LazyImage)
