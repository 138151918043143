import React from 'react'
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { type ComparativeAnalysis } from 'features/trade-analyzer/model/tradeReportViewModel'
import PropTypes from 'prop-types'

interface TradeAnalysisCardProps {
  comparativeAnalysis: ComparativeAnalysis
}

const ComparativeAnalysisCard: React.FC<TradeAnalysisCardProps> = ({ comparativeAnalysis }): JSX.Element => {
  const { comparisonText, keyDifferences } = comparativeAnalysis

  return <>
        <Box mt={3}>
            <Typography my={2}>{comparisonText}</Typography>
            <TableContainer component={Paper} elevation={2}>
                <Table>
                    <TableHead sx={{ backgroundColor: 'secondary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                                Factor
                            </TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                                Outgoing Player Advantage
                            </TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                                Incoming Player Advantage
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keyDifferences.map((diff, index) => (
                            <TableRow key={index}>
                                <TableCell>{diff.factor}</TableCell>
                                <TableCell>{diff.outgoingPlayerAdvantage}</TableCell>
                                <TableCell>{diff.incomingPlayerAdvantage}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        </>
}

ComparativeAnalysisCard.propTypes = {
  comparativeAnalysis: PropTypes.shape({
    comparisonText: PropTypes.string.isRequired,
    keyDifferences: PropTypes.arrayOf(
      PropTypes.shape({
        factor: PropTypes.string.isRequired,
        outgoingPlayerAdvantage: PropTypes.string.isRequired,
        incomingPlayerAdvantage: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
}

export default React.memo(ComparativeAnalysisCard)
