import { Divider, Grid, Paper } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import PlayerStatisticGridItem from 'components/shared-components/PlayerStatisticSection'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import FantasyProfileHeader from './FantasyProfileHeader'

interface PlayerStatsSwipeableDrawerProps {
  open: boolean
  height?: number | string
  onOpen: () => void
  player: ConnectedYahooFantasyPlayer
  onClose: () => void
}

const PlayerStatsSwipeableDrawer: React.FC<PlayerStatsSwipeableDrawerProps> = ({ open, onClose, onOpen, height, player }) => {
  const { isFetching, data: playerStatistics, refetch: getPlayerStatistics } = useQuery<YahooFantasyPlayerStatistics>({
    queryKey: [ApiQueryKeys.GetYahooFantasyPlayerStatistics],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyPlayerStatistics](player.playerId)).data
    },
    enabled: !(player.playerId === 0)
  })

  const { data: fantaHockeyScore, refetch: getFantaHockeyPlayerScore } = useQuery<number>({
    queryKey: [ApiQueryKeys.GetPlayerFantaHockeyScore],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetPlayerFantaHockeyScore](player.playerId)).data
    },
    enabled: !(player.playerId === 0)
  })

  React.useEffect(() => {
    const fetchPlayerStatistics = async (): Promise<void> => {
      if (player.playerId !== 0) {
        await getPlayerStatistics()
        await getFantaHockeyPlayerScore()
      }
    }
    void fetchPlayerStatistics()
  }, [player])

  return <FantasyProfileHeader isFetching={isFetching} open={open} onClose={onClose} onOpen={onOpen} fantaHockeyScore={fantaHockeyScore} height={height} player={player} playerStatistics={playerStatistics}>
        {/* Content */}
        <Paper sx={{ boxShadow: 'none', mt: 6 }}>
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.goals} statisticTitle="Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.assists} statisticTitle="Assists" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.points} statisticTitle="Points" maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.shots} statisticTitle="Shots" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.plusMinus} statisticTitle="+/-" statisticTooltip='Plus Minus' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.penaltyMinutes} statisticTitle="PIM" statisticTooltip='Penalty Minutes' maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.faceoffWinPercentage} statisticTitle="FO %" statisticTooltip='Face Off Percentage' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.shootingPercentage} statisticTitle="SH %" statisticTooltip='Shooting Percentage' maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.gameWinningGoals} statisticTitle="GWG" statisticTooltip='Game Winning Goals' maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.firstGoals} statisticTitle="FG" statisticTooltip="First Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.giveaways} statisticTitle="GA" statisticTooltip="Give Aways" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.hits} statisticTitle="Hits" maxWidth='50px' />
            </Grid>
            <Divider />
            {/* Core Stats Section */}
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.individualFenwickFor} statisticTooltip='Individual Fenwick For' statisticTitle="IFF" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.individualCorsiFor} statisticTooltip='Individual Corsi For' statisticTitle="ICF" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.powerPlayGoals} statisticTooltip='Power Play Goals' statisticTitle="PP Goals" maxWidth='80px' />
            </Grid>
            {/* Core Stats Section */}
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.overtimeGoals} statisticTooltip='Overtime Goals' statisticTitle="OT Goals" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.takeaways} statisticTitle="Take Aways" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.gamesPlayed} statisticTitle="Games Played" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.goalsPer60?.toFixed(2)} statisticTitle="Goals Per 60 min" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.assistsPer60?.toFixed(2)} statisticTitle="Assists Per 60 min" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.pointsPer60?.toFixed(2)} statisticTitle="Points Per 60 min" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={playerStatistics?.blockedShots} statisticTitle="Total Blocked Shots" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.blockedShotsPer60} statisticTitle="Blocked Shots Per 60 Min" maxWidth='75px' />
              <PlayerStatisticGridItem statistic={playerStatistics?.shotAttemptsBlocked} statisticTitle="Shot Attempts Blocked" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={`${playerStatistics?.timeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Time On Ice Per Game' statisticTitle="TOI Per Game" maxWidth='40px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.evTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Even Strength Time on Ice Per Game' statisticTitle="EV TOI Per Game" maxWidth='50px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.ppTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Power Play Time on Ice Per Game' statisticTitle="PP TOI Per Game" maxWidth='50px' />
            </Grid>
            <Divider />
            <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <PlayerStatisticGridItem statistic={`${playerStatistics?.timeOnIcePerShift?.toFixed(2) ?? 0} Sec`} statisticTooltip='Time On Ice Per Shift' statisticTitle="TOI Per Shift" maxWidth='40px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.otTimeOnIcePerOtGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Overtime Time On Ice Per Overtime Game' statisticTitle="OT TOI Per OT Game" maxWidth='60px' />
              <PlayerStatisticGridItem statistic={`${playerStatistics?.shTimeOnIcePerGame?.toFixed(2) ?? 0} Min`} statisticTooltip='Shorthanded Time On Ice Per Game' statisticTitle="SH TOI Per Game" maxWidth='60px' />
            </Grid>
        </Paper>
  </FantasyProfileHeader>
}

PlayerStatsSwipeableDrawer.displayName = 'PlayerStatsSwipeableDrawer'
PlayerStatsSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default React.memo(PlayerStatsSwipeableDrawer)
