import axios from 'axios'
import { LoginLocalStorageKey } from 'features/login/models/generic/OAuthResponse'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useNavigate } from 'react-router'

const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
const httpAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_FANTAHOCKEY_API_URL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json'
  }
})

const setupInterceptors = (): void => {
  const navigate = useNavigate()
  const { openSnackbar } = useSnackbar()
  const fantaHockeyAuth = useFantaHockeyContext()

  httpAxiosInstance.interceptors.request.use(
    (config) => {
      if ((config.headers != null) && localStorage.getItem(LoginLocalStorageKey) != null) {
        config.headers.TimeZoneOffset = timezoneOffset
        config.headers.Authorization = `Bearer ${localStorage.getItem(LoginLocalStorageKey)}`
      }
      return config
    }, async (error) => {
      return await Promise.reject(error)
    })

  httpAxiosInstance.interceptors.response.use(
    (response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response
    }, async (error) => {
      if (error?.response !== null && (error?.response?.status === 403 || error?.response?.status === 401)) {
        openSnackbar('Session expired. Please login again.', 'error')
        fantaHockeyAuth.logout()
        navigate('/login', { replace: true })
      } else if (error?.response !== null && error?.response?.status >= 400) {
        openSnackbar(`Error occured. Please try again later (${error?.response?.status})`, 'error')
      } else {
        return await Promise.reject(error)
      }
    })
}

export { httpAxiosInstance, setupInterceptors }
