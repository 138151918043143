import Flag from 'react-world-flags'
import React from 'react'

interface PlayerFlagProps {
  country: string | undefined
  height?: number | undefined
  width?: number | undefined
}

const PlayerFlag: React.FC<PlayerFlagProps> = ({ country, width = 48, height = 48 }): JSX.Element => {
  return <Flag code={country} width={width} height={height} />
}

export default React.memo(PlayerFlag)
