import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { type XFactorDimension } from 'features/trade-analyzer/model/tradeReportViewModel'

interface XFactorDimensionProps {
  xFactorDimensions: XFactorDimension[]
}

const XFactorDimensionTable: React.FC<XFactorDimensionProps> = ({ xFactorDimensions }): JSX.Element => {
  return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 320 }} aria-label="trade-analysis">
                <TableHead sx={{ backgroundColor: 'secondary.main' }}>
                    <TableRow>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Player Name</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>X-Factor Score</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Intangible Highlight</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {xFactorDimensions.map(row => (
                        <TableRow
                            key={row.playerName}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}>
                            <TableCell component="th" scope="row">
                                {row.playerName}
                            </TableCell>
                            <TableCell align="center">{row.xFactorScore}</TableCell>
                            <TableCell align="center">{row.intangibleHighlight}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
  )
}

export default React.memo(XFactorDimensionTable)
