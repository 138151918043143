import React from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { type PointsTable } from 'features/trade-analyzer/model/tradeReportViewModel'
import PropTypes from 'prop-types'

interface TradeAnalysisCardProps {
  pointsTable: PointsTable[]
}

const TradeAnalysis: React.FC<TradeAnalysisCardProps> = ({ pointsTable }): JSX.Element => {
  return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 320 }} aria-label="trade-analysis">
            <TableHead sx={{ backgroundColor: 'secondary.main' }}>
                <TableRow>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Player Name</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Advanced Performance Trends</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Fantasy Points & Historical Reliablity</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Fantasy League Settings Fit</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>X-Factor Score</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Total Points</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {pointsTable.map((row) => (
                    <TableRow
                        key={row.playerName}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            {row.playerName}
                        </TableCell>
                        <TableCell align="center">{row.advancedStatsPerformanceTrends}</TableCell>
                        <TableCell align="center">{row.fantasyPointsHistoricalReliability}</TableCell>
                        <TableCell align="center">{row.leagueSettingsFit}</TableCell>
                        <TableCell align="center">{row.xFactorIntangibles}</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>{row.totalPoints}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

TradeAnalysis.propTypes = {
  pointsTable: PropTypes.arrayOf(
    PropTypes.shape({
      playerName: PropTypes.string.isRequired,
      advancedStatsPerformanceTrends: PropTypes.number.isRequired,
      fantasyPointsHistoricalReliability: PropTypes.number.isRequired,
      leagueSettingsFit: PropTypes.number.isRequired,
      xFactorIntangibles: PropTypes.number.isRequired,
      totalPoints: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
}

export default React.memo(TradeAnalysis)
