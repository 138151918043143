import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'

import HomeIcon from '@mui/icons-material/Home'

import { ListItemButton, ListItemIcon } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Group, SportsHockey, Leaderboard, AutoMode, Work, Add } from '@mui/icons-material'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'

interface AppMenuListProps {
  anchor: string
  toggleMenuDrawer: (isOpen: boolean) => void
}

const AppMenuList: React.FC<AppMenuListProps> = React.memo(({ anchor, toggleMenuDrawer }): JSX.Element => {
  const location = useLocation()
  const store = useFantaHockeyStore()

  const MenuLinkItem = (to: string, text: string, currentLocation: string, icon: JSX.Element): JSX.Element => React.useMemo(() => {
    return (
            <Link to={to} onClick={() => { toggleMenuDrawer(false) }}>
                <ListItemButton selected={to === currentLocation} key={2}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </Link>
    )
  }, [currentLocation, icon, text, to, toggleMenuDrawer])

  return (
        <>
            <React.Fragment>
                <Box
                    mt={7}
                    sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                    role="presentation"
                >
                    <List>
                        {MenuLinkItem('/home', 'Home', location.pathname, <HomeIcon sx={{ color: 'text.primary' }} />)}
                        {MenuLinkItem('/live-scores', 'Live Scores', location.pathname, <SportsHockey sx={{ color: 'text.primary' }} />)}
                        {MenuLinkItem('/league-leaders', 'League Leaders', location.pathname, <Leaderboard sx={{ color: 'text.primary' }} />)}
                        {MenuLinkItem('/my-fantasy-team', 'My Fantasy Team', location.pathname, <Group sx={{ color: 'text.primary' }} />)}
                        {store.isConnectedToYahooFantasy ? <></> : MenuLinkItem('/connect-my-fantasy-team', 'Connect Team', location.pathname, <Add sx={{ color: 'text.primary' }} />)}
                        {store.isConnectedToYahooFantasy ? MenuLinkItem('/manage-my-fantasy-team', 'Manage Team', location.pathname, <Work sx={{ color: 'text.primary' }} />) : <></>}
                        {MenuLinkItem('/trade-edge-ai', 'Trade Edge', location.pathname, <AutoMode sx={{ color: 'text.primary' }} />)}
                    </List>
                </Box>
            </React.Fragment>
        </>
  )
})

AppMenuList.propTypes = {
  anchor: PropTypes.string.isRequired,
  toggleMenuDrawer: PropTypes.func.isRequired
}

AppMenuList.displayName = 'AppMenuList'
export default AppMenuList
