import { type SvgIconOwnProps, type SvgIconProps } from '@mui/material'
import React from 'react'

interface DynamicIconProps {
  Icon: React.ComponentType<SvgIconProps>
  color?: SvgIconOwnProps['color']
  htmlColor?: string | undefined
  fontSizeMd?: number
  fontSizeSm?: number
}

const DynamicIcon: React.FC<DynamicIconProps> = (
  {
    Icon,
    color,
    htmlColor = 'white',
    fontSizeMd = 72,
    fontSizeSm = 48
  }
) => {
  return (
    <>
      <Icon color={color} htmlColor={htmlColor} sx={{ fontSize: fontSizeSm, display: { sm: 'block', xs: 'block', md: 'none' } }} />
      <Icon color={color} htmlColor={htmlColor} sx={{ fontSize: fontSizeMd, display: { sm: 'none', xs: 'none', md: 'block' } }} />
    </>

  )
}

export default DynamicIcon
