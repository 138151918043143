import { Box, styled, useTheme } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { type WeeklyFantasyTeamPerformance } from 'features/home/models/weeklyPerformanceModel'
import React from 'react'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { type AxisOptions, Chart } from 'react-charts'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import ConnectYahooFantasyTeam from 'components/shared-components/ConnectYahooAccount'

const WeeklyFantasyTeamPerformanceDashboard = (): JSX.Element => {
  const theme = useTheme()
  const store = useFantaHockeyStore()
  const queryClient = useQueryClient()

  const ChartContainer = styled(Box)`
  & .tickLabel {
    fill: white !important;
  }
  & .tick {
    & line {
      stroke: white !important;
      stroke-width: 0.1px;
    }
  }
`
  const { isFetching, data: weeklyFantasyTeamPerformance } = useQuery<WeeklyFantasyTeamPerformance[]>({
    queryKey: [ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery]()).data
    },
    enabled: store.isConnectedToYahooFantasy,
    initialData: []
  })
  React.useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({
        queryKey: [ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery]
      })
    }
  }, [])

  const dashboardMetricsData = [
    {
      label: 'Goals',
      data: weeklyFantasyTeamPerformance?.map((metric, index) => ({
        week: new Date(metric.key),
        value: metric.value.goals
      })) ?? []
    },
    {
      label: 'Assists',
      data: weeklyFantasyTeamPerformance?.map((metric, index) => ({
        week: new Date(metric.key),
        value: metric.value.assists
      })) ?? []
    },
    {
      label: 'Points',
      data: weeklyFantasyTeamPerformance?.map((metric, index) => ({
        week: new Date(metric.key),
        value: metric.value.points
      })) ?? []
    },
    {
      label: 'Power Play Goals',
      data: weeklyFantasyTeamPerformance?.map((metric, index) => ({
        week: new Date(metric.key),
        value: metric.value.powerPlayGoals
      })) ?? []
    },
    {
      label: 'Power Play Points',
      data: weeklyFantasyTeamPerformance?.map((metric, index) => ({
        week: new Date(metric.key),
        value: metric.value.powerPlayPoints
      })) ?? []
    }
  ]

  const primaryAxis = React.useMemo(
    (): AxisOptions<{ week: Date, value: number }> => ({
      getValue: data => data.week,
      styles: {
        color: theme.palette.text.primary
      }
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): Array<AxisOptions<{ week: Date, value: number }>> => [
      {
        getValue: data => data.value,
        styles: {
          color: theme.palette.text.primary,
          columnRuleColor: theme.palette.text.primary,
          colorRendering: 'optimizeSpeed',
          colorScheme: 'dark',
          fill: theme.palette.text.primary
        }
      }
    ],
    []
  )

  if (!store.isConnectedToYahooFantasy) {
    return <ConnectYahooFantasyTeam />
  }

  if (isFetching) {
    return (
      <Box>
        <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
      </Box>
    )
  }

  return <ChartContainer sx={{ width: '100%', height: 350, minHeight: 350, maxHeight: 450 }}>
    {isFetching
      ? 'Loading...'
      : <Chart options={{
        secondaryCursor: true,
        data: dashboardMetricsData,
        primaryAxis,
        secondaryAxes
      }} />}
  </ChartContainer>
}

WeeklyFantasyTeamPerformanceDashboard.displayName = 'WeeklyFantasyTeamPerformanceDashboard'

export default React.memo(WeeklyFantasyTeamPerformanceDashboard)
