import { type AxiosResponse } from 'axios'
import { type ConnectedYahooFantasyPlayer, type ConnectedYahooFantasyTeam } from 'features/fantasy-team/models/yahooConnectedTeam'
import { type YahooFantasyGoalieStatistics } from 'features/fantasy-team/models/yahooFantasyGoalieStatistics'
import { type YahooConnectedTeamProfile, type YahooLeagueTeam } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import { type LiveGameScore } from 'features/games/models/liveGameScoreModels'
import { type LeagueLeadersDashboardViewModel } from 'features/league-leaders/models/leagueLeadersModels'
import { type WeeklyFantasyTeamPerformance } from 'features/home/models/weeklyPerformanceModel'
import { type FavoriteNhlTeamProfile, type FavoriteNhlTeamSelection } from 'features/settings/nhlTeamSelection'
import { type PlayerSearchResult } from 'features/trade-analyzer/model/playerSearchResult'
import { type TradeSuggestions } from 'features/trade-analyzer/model/tradeSuggestion'
import { type FantasyTeamPerformanceAnalysis } from 'features/home/models/fantasyTeamPerformanceAnalysis'
import { httpAxiosInstance } from 'services/http/axiosInstance'

export enum ApiQueryKeys {
  GetLeagueLeadersDashboardQuery = 'GetLeagueLeadersDashboardQuery',
  IsUserConnectedToYahooQuery = 'IsUserConnectedToYahooQuery',
  GetAllNhlTeamsQuery = 'GetAllNhlTeamsQuery',
  GetUsersFavoriteNhlTeamQuery = 'GetUsersFavoriteNhlTeamQuery',
  ConnectToYahooFantasyProfileQuery = 'ConnectToYahooFantasyProfileQuery',
  GetLiveGameScoresQuery = 'GetLiveGameScoresQuery',
  GetYahooFantasyTeamsQuery = 'GetYahooFantasyTeamsQuery',
  GetConnectedYahooFantasyTeamQuery = 'GetConnectedYahooFantasyTeamQuery',
  GetYahooFantasyPlayerStatistics = 'GetYahooFantasyPlayerStatistics',
  GetYahooFantasyGoalieStatistics = 'GetYahooFantasyGoalieStatistics',
  GetPlayerFantaHockeyScore = 'GetPlayerFantaHockeyScore',
  GetGoalieFantaHockeyScore = 'GetGoalieFantaHockeyScore',
  GetSearchForPlayersQuery = 'GetSearchForPlayersQuery',
  GetTradeEdgeSuggestionsQuery = 'GetTradeEdgeSuggestionsQuery',
  GetTopFantasyPlayersQuery = 'GetTopFantasyPlayersQuery',
  GetConnectedFantasyTeamProfileQuery = 'GetConnectedFantasyTeamInfoQuery',
  GetWeeklyFantasyTeamPerformanceQuery = 'GetWeeklyFantasyTeamPerformanceQuery',
  GetFantasyTeamPerformanceAnalysisQuery = 'GetFantasyTeamPerformanceAnalysisQuery'
}

export type ApiQueryResponse<T> = Promise<AxiosResponse<T>>

type GetLeagueLeadersDashboardQuery = () => ApiQueryResponse<LeagueLeadersDashboardViewModel>
type GetAllNhlTeamsQuery = () => ApiQueryResponse<FavoriteNhlTeamSelection[]>
type GetUsersFavoriteNhlTeamQuery = () => ApiQueryResponse<FavoriteNhlTeamProfile>
type IsUserConnectedToYahooQuery = () => ApiQueryResponse<boolean>
type ConnectToYahooFantasyProfileQuery = (code: string) => ApiQueryResponse<boolean>
type GetLiveGameScoresQuery = () => ApiQueryResponse<LiveGameScore[]>
type GetYahooFantasyTeamQuery = (fantasyLeagueId: string) => ApiQueryResponse<YahooLeagueTeam[]>
type GetConnectedYahooFantasyTeamQuery = () => ApiQueryResponse<ConnectedYahooFantasyTeam>
type GetYahooFantasyPlayerStatistics = (playerId: number) => ApiQueryResponse<YahooFantasyPlayerStatistics>
type GetPlayerFantaHockeyScore = (playerId: number) => ApiQueryResponse<number>
type GetYahooFantasyGoalieStatistics = (playerId: number) => ApiQueryResponse<YahooFantasyGoalieStatistics>
type GetGoalieFantaHockeyScore = (playerId: number) => ApiQueryResponse<number>
type GetSearchForPlayersQuery = (searchTerm: string) => ApiQueryResponse<PlayerSearchResult[]>
type GetTradeEdgeSuggestionsQuery = () => ApiQueryResponse<TradeSuggestions[]>
type GetTopFantasyPlayersQuery = () => ApiQueryResponse<ConnectedYahooFantasyPlayer[]>
type GetConnectedFantasyTeamInfoQuery = () => ApiQueryResponse<YahooConnectedTeamProfile>
type GetWeeklyFantasyTeamPerformanceQuery = () => ApiQueryResponse<WeeklyFantasyTeamPerformance[]>
type GetFantasyTeamPerformanceAnalysisQuery = () => ApiQueryResponse<FantasyTeamPerformanceAnalysis>

export interface ApiQueryServiceType {
  [ApiQueryKeys.GetLeagueLeadersDashboardQuery]: GetLeagueLeadersDashboardQuery
  [ApiQueryKeys.IsUserConnectedToYahooQuery]: IsUserConnectedToYahooQuery
  [ApiQueryKeys.GetAllNhlTeamsQuery]: GetAllNhlTeamsQuery
  [ApiQueryKeys.GetUsersFavoriteNhlTeamQuery]: GetUsersFavoriteNhlTeamQuery
  [ApiQueryKeys.ConnectToYahooFantasyProfileQuery]: ConnectToYahooFantasyProfileQuery
  [ApiQueryKeys.GetLiveGameScoresQuery]: GetLiveGameScoresQuery
  [ApiQueryKeys.GetYahooFantasyTeamsQuery]: GetYahooFantasyTeamQuery
  [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]: GetConnectedYahooFantasyTeamQuery
  [ApiQueryKeys.GetYahooFantasyPlayerStatistics]: GetYahooFantasyPlayerStatistics
  [ApiQueryKeys.GetPlayerFantaHockeyScore]: GetPlayerFantaHockeyScore
  [ApiQueryKeys.GetYahooFantasyGoalieStatistics]: GetYahooFantasyGoalieStatistics
  [ApiQueryKeys.GetGoalieFantaHockeyScore]: GetGoalieFantaHockeyScore
  [ApiQueryKeys.GetSearchForPlayersQuery]: GetSearchForPlayersQuery
  [ApiQueryKeys.GetTradeEdgeSuggestionsQuery]: GetTradeEdgeSuggestionsQuery
  [ApiQueryKeys.GetTopFantasyPlayersQuery]: GetTopFantasyPlayersQuery
  [ApiQueryKeys.GetConnectedFantasyTeamProfileQuery]: GetConnectedFantasyTeamInfoQuery
  [ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery]: GetWeeklyFantasyTeamPerformanceQuery
  [ApiQueryKeys.GetFantasyTeamPerformanceAnalysisQuery]: GetFantasyTeamPerformanceAnalysisQuery
}

export const ApiQueryService: ApiQueryServiceType = {
  [ApiQueryKeys.GetLeagueLeadersDashboardQuery]: async () => {
    return await httpAxiosInstance.get<LeagueLeadersDashboardViewModel>('league-leaders/dashboard')
  },
  [ApiQueryKeys.IsUserConnectedToYahooQuery]: async () => {
    return await httpAxiosInstance.get<boolean>('yahoo/fantasy/team/has-connected-team')
  },
  [ApiQueryKeys.GetAllNhlTeamsQuery]: async () => {
    return await httpAxiosInstance.get<FavoriteNhlTeamSelection[]>('nhl/teams')
  },
  [ApiQueryKeys.GetUsersFavoriteNhlTeamQuery]: async () => {
    return await httpAxiosInstance.get<FavoriteNhlTeamProfile>('settings/favorite/nhl/team')
  },
  [ApiQueryKeys.ConnectToYahooFantasyProfileQuery]: async (code: string) => {
    return await httpAxiosInstance.get<boolean>(`/yahoo/fantasy/profile/callback?code=${code}`)
  },
  [ApiQueryKeys.GetLiveGameScoresQuery]: async () => {
    return await httpAxiosInstance.get<LiveGameScore[]>('live/games')
  },
  [ApiQueryKeys.GetYahooFantasyTeamsQuery]: async (fantasyLeagueId: string) => {
    return await httpAxiosInstance.get<YahooLeagueTeam[]>(`/yahoo/fantasy/league/${fantasyLeagueId}/teams`)
  },
  [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]: async () => {
    return await httpAxiosInstance.get<ConnectedYahooFantasyTeam>('/yahoo/fantasy/user/team')
  },
  [ApiQueryKeys.GetYahooFantasyPlayerStatistics]: async (playerId: number) => {
    return await httpAxiosInstance.get<YahooFantasyPlayerStatistics>(`/yahoo/fantasy/player/${playerId}/statistics`)
  },
  [ApiQueryKeys.GetPlayerFantaHockeyScore]: async (playerId: number) => {
    return await httpAxiosInstance.get<number>(`/yahoo/fantasy/player/${playerId}/fantahockey-score`)
  },
  [ApiQueryKeys.GetYahooFantasyGoalieStatistics]: async (playerId: number) => {
    return await httpAxiosInstance.get<YahooFantasyGoalieStatistics>(`/yahoo/fantasy/goalie/${playerId}/statistics`)
  },
  [ApiQueryKeys.GetGoalieFantaHockeyScore]: async (playerId: number) => {
    return await httpAxiosInstance.get<number>(`/yahoo/fantasy/goalie/${playerId}/fantahockey-score`)
  },
  [ApiQueryKeys.GetSearchForPlayersQuery]: async (searchTerm: string) => {
    return await httpAxiosInstance.get<PlayerSearchResult[]>(`/players/search/${searchTerm}`)
  },
  [ApiQueryKeys.GetTradeEdgeSuggestionsQuery]: async () => {
    return await httpAxiosInstance.get<TradeSuggestions[]>('/fantasy/trade/suggestions')
  },
  [ApiQueryKeys.GetTopFantasyPlayersQuery]: async () => {
    return await httpAxiosInstance.get<ConnectedYahooFantasyPlayer[]>('/home/dashboard')
  },
  [ApiQueryKeys.GetConnectedFantasyTeamProfileQuery]: async () => {
    return await httpAxiosInstance.get<YahooConnectedTeamProfile>('/yahoo/fantasy/user/team/profile')
  },
  [ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery]: async () => {
    return await httpAxiosInstance.get<WeeklyFantasyTeamPerformance[]>('/home/fantasy/weekly/performance')
  },
  [ApiQueryKeys.GetFantasyTeamPerformanceAnalysisQuery]: async () => {
    return await httpAxiosInstance.get<FantasyTeamPerformanceAnalysis>('/yahoo/fantasy/report')
  }
}
